<template>
    <div class="modal-wrapper">
        <transition name="dialog"
                    appear>
            <div v-if="!modalStore.showExitAnimation"
                 class="modal d-flex justify-content-center align-items-center modal-transition"
                 @click="modalStore.setCustomErrorModal(false)">
                <div class="modal-wrapper">
                    <div class="modal-content justify-content-between justify-content-md-start"
                         @click.stop>
                        <span class="close-button"
                              @click="modalStore.setCustomErrorModal(false)">&times;</span>
                        <div>
                            <div class="locked-image d-flex justify-content-center align-items-center">
                                <img :src="(asset_cdn('assets/missions/in-game-images/motivation-char-assets/failrow1.svg') as string)"
                                     :alt="errorMessage.subtitle"
                                     :title="errorMessage.subtitle">
                            </div>
                            <div class="modal-title text-center mb-3">
                                {{ errorMessage.subtitle }}
                            </div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center">
                            <button class="quiz-button--correct"
                                    @click="modalStore.setCustomErrorModal(false)">
                                {{ errorMessage.buttonText }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, onBeforeUnmount, reactive } from 'vue';
    import { asset_cdn } from '@/js/helpers/helpers';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { EventTypeEnum, KeyEnum } from '@/js/enums/enums';
    import { deleteCookie, getCookieValue } from '@/js/helpers/cookieHelpers';
    import { COOKIES_KEYS } from '@/js/quests/game/constants/cookiesKeys';

    const modalStore = useModalStore();

    const onEscapePressed = (event: KeyboardEvent) => {
        if (event.key === KeyEnum.ESCAPE || event.key === KeyEnum.ESC || event.which === 27) {
            modalStore.setCustomErrorModal(false);
        }
    };

    const errorMessage = reactive({
        subtitle: '',
        buttonText: '',
    });

    const setErrorMessage = (message: {subtitle: string, buttonText: string}) => {
        errorMessage.subtitle = message.subtitle || '';
        errorMessage.buttonText = message.buttonText || '';
    };

    onMounted(() => {
        document.addEventListener(EventTypeEnum.KEYDOWN, onEscapePressed);

        const message = JSON.parse(getCookieValue(COOKIES_KEYS.CUSTOM_ERROR_MODAL) || '{}');
        const isMessageEmpty = Object.keys(message).length === 0;

        if (isMessageEmpty) {
            return;
        }

        setErrorMessage(message);
        deleteCookie(COOKIES_KEYS.CUSTOM_ERROR_MODAL);
    });

    onBeforeUnmount(() => {
        document.removeEventListener(EventTypeEnum.KEYDOWN, onEscapePressed);
    });
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/design-system/variables";
@import "@/sass/partials/breakpoints";

.modal-transition {
    .modal-wrapper {
        max-width: 400px;
        max-height: 500px;
    }

    .modal-content {
        padding: 27px;

        @media (max-width: $md-breakpoint-max-width) {
            padding: 0 16px 16px 16px;
        }
    }

    div {
        color: $baseWhite;
    }

    .modal-title {
        padding: 16px 0;
        font-size: 16px;
        font-weight: 500;
    }

    .locked-image img {
        width: 150px;
    }

    @media (max-width: 768px) {
        .modal-title {
            font-size: 22px;
        }

        .locked-image img {
            width: 100px;
        }
    }

    .quiz-button--correct {
        padding: 0 32px;
    }
}
</style>
