<template>
    <transition name="dialog"
                appear>
        <div v-if="!modalStore.showExitAnimation"
             class="modal d-flex justify-content-center align-items-center modal-transition"
             @click="modalStore.setHeartRecoveryModal(false)">
            <div class="modal-wrapper">
                <div class="modal-content text-center p-5 justify-content-between justify-content-md-start"
                     @click.stop>
                    <div class="text-left">
                        <span class="close-button"
                              @click="modalStore.setHeartRecoveryModal(false)">&times;</span>
                    </div>
                    <div>
                        <div class="recovery-image d-flex justify-content-center align-items-center">
                            <img :src="asset_cdn('assets/missions/hearts-recovered.svg')"
                                 :alt="getTrans('quests-dashboard.yourHeartsHaveRecovered', 'Your hearts have recovered')"
                                 :title="getTrans('quests-dashboard.yourHeartsHaveRecovered', 'Your hearts have recovered')"
                                 width="120"
                                 height="145">
                        </div>
                        <div class="modal-title text-center">
                            <Translate translationKey="quests-dashboard.heartRecoveryTitle"
                                       :replacements="{number: heartsRecovered}"
                                       :isPlural="heartsRecovered !== 1" />
                        </div>
                        <div class="hearts">
                            <div v-for="index in 5"
                                 :key="'slot-' + index"
                                 class="heart-slot">
                                <img class="heart"
                                     :src="asset_cdn('assets/missions/heart-empty.svg')"
                                     :alt="getTrans('quests-dashboard.emptyHeart', 'Empty heart')"
                                     :title="getTrans('quests-dashboard.emptyHeart', 'Empty heart')">
                                <img v-if="index <= props.previousHeartCount"
                                     class="heart heart-full"
                                     :src="asset_cdn('assets/missions/heart-full.svg')"
                                     :alt="getTrans('quests-dashboard.fullHeart', 'Full heart')"
                                     :title="getTrans('quests-dashboard.fullHeart', 'Full heart')">
                                <img v-if="index > props.previousHeartCount && index <= props.currentHeartCount"
                                     :class="`heart heart-animation heart-${ index }`"
                                     :src="asset_cdn('assets/missions/heart-full.svg')"
                                     :alt="getTrans('quests-dashboard.fullHeart', 'Full heart')"
                                     :title="getTrans('quests-dashboard.fullHeart', 'Full heart')">
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center align-items-center mt-3">
                        <button class="quiz-button--primary"
                                @click="modalStore.setHeartRecoveryModal(false)">
                            <Translate translationKey="quests-dashboard.keepGaining"
                                       fallback="Keep Gaining" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
    import {
        onBeforeUnmount, onMounted, computed,
    } from 'vue';
    import { asset_cdn } from '@/js/helpers/helpers';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { getTrans } from '@/js/helpers/getTrans';
    import { EventTypeEnum, KeyEnum } from '@/js/enums/enums';
    import { useModalActive } from '@/js/composables/useModalActive';

    const props = defineProps({
        currentHeartCount: {
            type: Number,
            required: true,
        },
        previousHeartCount: {
            type: Number,
            required: true,
        },
    });

    const modalStore = useModalStore();

    const heartsRecovered = computed(() => props.currentHeartCount - props.previousHeartCount);

    const onEscapePressed = (event: KeyboardEvent) => {
        if (event.key === KeyEnum.ESCAPE || event.key === KeyEnum.ESC || event.which === 27) {
            modalStore.setHeartRecoveryModal(false);
        }
    };

    useModalActive();

    onMounted(() => {
        document.addEventListener(EventTypeEnum.KEYDOWN, onEscapePressed);
    });

    onBeforeUnmount(() => {
        document.removeEventListener(EventTypeEnum.KEYDOWN, onEscapePressed);
    });
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";
@import "@/js/quests/global/sass/mixins/_buttons";
@import "@/sass/partials/breakpoints";

.modal-transition {
    .modal-wrapper {
        max-width: 450px;
        max-height: 500px;
    }

    .modal-content {
        padding: 16px 11px;

        @media (max-width: $md-breakpoint-max-width) {
            padding: 0 16px 16px 16px;
        }
    }

    div {
        color: $baseWhite;
    }

    .hearts {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: space-between;

        img {
            max-width: 50px;
        }

        .heart-slot {
            position: relative;
            display: flex;
            width: 100%;
            min-height: 65px;
            align-items: center;
            justify-content: center;

            .heart {
                position: absolute;
                width: 100%;
            }

            .heart-full {
                transform: scale(1.05);
            }

            .heart-animation {
                animation: heartPopIn 1s forwards;
            }
        }

        @for $i from 1 through 5 {
            .heart-animation.heart-#{$i} {
                animation-delay: 0.6s + ($i - 1) * 0.3s;
                opacity: 0;
            }
        }
    }

    @keyframes heartPopIn {
        0% {
            opacity: 0;
            transform: scale(0);
        }
        100% {
            opacity: 1;
            transform: scale(1.01);
        }
    }

    .modal-title {
        padding: 16px 0;
        font-size: 16px;
        font-weight: 500;
    }

    .recovery-image img {
        max-width: 120px;
    }

    .quiz-button--primary {
        @include primary-button;
        margin-bottom: 4px;
    }
}
</style>
