<template>
    <transition name="dialog"
                appear>
        <div v-if="!modalStore.showExitAnimation"
             class="modal d-flex justify-content-center align-items-center modal-transition"
             @click="modalStore.setBybitDealRewardClaimedModal(false)">
            <div class="modal-wrapper">
                <div class="modal-content text-center p-5 justify-content-between justify-content-md-start"
                     @click.stop>
                    <div class="text-left">
                        <span class="close-button"
                              @click="modalStore.setBybitDealRewardClaimedModal(false)">&times;</span>
                    </div>
                    <div>
                        <div class="modal-content-animated"
                             :class="{ 'is-animated': showBits }">
                            <div v-if="showBits">
                                <h1 class="bits text-uppercase p-0 m-0 mb-3">
                                    <span class="bits-value">
                                        <img :src="asset_cdn('assets/missions/coin.svg')"
                                             :alt="`${getTrans('quests-dashboard.reward', 'Reward')}: ${bits} ${bybitDealRewardClaimedNotification?.data?.reward_amount?.currency}s`"
                                             :title="`${getTrans('quests-dashboard.reward', 'Reward')}: ${bits} ${bybitDealRewardClaimedNotification?.data?.reward_amount?.currency}s`"
                                             class="mb-2"
                                             width="50">
                                        {{ bits }}
                                        <span class="text-capitalize">{{
                                            bybitDealRewardClaimedNotification?.data?.reward_amount?.currency
                                        }}S</span>
                                    </span>
                                </h1>
                            </div>
                        </div>
                        <div class="modal-title">
                            {{ bybitDealRewardClaimedNotification?.data?.title }}
                        </div>
                        <div class="modal-subtitle">
                            {{ bybitDealRewardClaimedNotification?.data?.subtitle }}
                        </div>
                    </div>
                    <div class="modal-content-animated is-animated d-flex justify-content-center align-items-center">
                        <button v-focus
                                class="quiz-button--correct mb-2"
                                @click="modalStore.setBybitDealRewardClaimedModal(false)"
                                @keyup.enter="modalStore.setBybitDealRewardClaimedModal(false)">
                            <Translate translationKey="quests-dashboard.earnMore"
                                       fallback="Earn More" /> 🔥
                        </button>
                    </div>
                </div>
            </div>
            <div class="position-fixed mb-2">
                <confetti-explosion v-bind="getConfettiProps" />
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
    import {
        computed,
        onBeforeUnmount, onMounted, ref, watchEffect,
    } from 'vue';
    import ConfettiExplosion from 'vue-confetti-explosion';
    import { useUserStore } from '@/js/store/quests/dashboard/UserStore';
    import { asset_cdn } from '@/js/helpers/helpers';
    import { NotificationModalTypeEnum } from '../../types/enums';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { Notification } from '../../types/types';
    import { getTrans } from '@/js/helpers/getTrans';
    import { EventTypeEnum, KeyEnum } from '@/js/enums/enums';
    import { REWARD_ANIMATION_DURATION_MS } from '../../../global/constants/rewardAnimation';
    import { useModalActive } from '@/js/composables/useModalActive';

    const userStore = useUserStore();
    const modalStore = useModalStore();

    let interval: NodeJS.Timeout | undefined;
    const bits = ref(0);
    const showBits = ref(false);

    const notifications = ref<Notification[]>([]);
    const bybitDealRewardClaimedNotification = ref();

    const onEscapePressed = (e: { key: string; which: number; }) => {
        if (e.key === KeyEnum.ESCAPE || e.key === KeyEnum.ESC || e.which === 27) {
            modalStore.setBybitDealRewardClaimedModal(false);
        }
    };

    const getConfettiProps = computed(() => ({
        duration: 2000,
    }) as unknown);

    watchEffect(() => {
        if (modalStore.modals.showBybitDealRewardClaimed) {
            notifications.value = userStore?.notifications;
            const filteredNotifications = notifications.value.filter(
                (notification) => notification.type === NotificationModalTypeEnum.BybitDealRewardClaimed,
            );
            bybitDealRewardClaimedNotification.value = filteredNotifications.length > 0 ? filteredNotifications[0] : null;
            if (bybitDealRewardClaimedNotification.value) {
                const ANIMATION_START_DELAY_MS = 1500;

                setTimeout(() => {
                    showBits.value = true;
                    let counter = 0;
                    const rewardAmount = bybitDealRewardClaimedNotification.value?.data?.reward_amount?.amount;
                    if (rewardAmount) {
                        const intervalDuration = REWARD_ANIMATION_DURATION_MS / rewardAmount;

                        interval = setInterval(() => {
                            if (counter < rewardAmount) {
                                counter += 2;
                                bits.value = counter;
                            } else {
                                clearInterval(interval);
                            }
                        }, intervalDuration);
                    }
                }, ANIMATION_START_DELAY_MS);
            }
        }
    });

    useModalActive();

    onMounted(() => {
        document.addEventListener(EventTypeEnum.KEYDOWN, onEscapePressed);
    });

    onBeforeUnmount(() => {
        document.removeEventListener(EventTypeEnum.KEYDOWN, onEscapePressed);
        if (interval) {
            clearInterval(interval);
        }
    });
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";
@import "@/sass/partials/breakpoints";

.modal-transition {
    .modal-wrapper {
        max-width: 500px;
        max-height: 500px;
    }

    .modal-content {
        padding: 16px 11px;

        .modal-content-animated {
            overflow: hidden;
            max-height: 0;
            transition: max-height 1.4s ease-in-out;
        }

        .modal-content-animated.is-animated {
            max-height: 500px;
        }

        @media (max-width: $md-breakpoint-max-width) {
            padding: 0 16px 16px 16px;
        }
    }

    div {
        color: $baseWhite;
    }

    .modal-title {
        padding-bottom: 16px;
        font-size: 26px;
        font-weight: 700;
        line-height: 32px;
    }

    .modal-subtitle {
        padding-bottom: 32px;
        font-size: 16px;
        font-weight: 500;
        line-height: 23px;
    }

    .bits {
        margin-bottom: 16px;
        color: #f1c92c;
        font-size: 65px;
        font-weight: 700;
        text-align: center;

        .bits-value {
            font-weight: 700;
            transition: 1s filter linear;

            span {
                font-size: 25px;
            }
        }
    }

    @media (max-width: 768px) {
        .modal-title {
            font-size: 40px;
            line-height: 46px;
        }

        .modal-subtitle {
            font-size: 16px;
        }

        .bits {
            font-size: 64px;

            img {
                width: 60px;
            }
        }
    }
}
</style>
